// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvDP1P_vgkYyD1rIHTmNBstqfqID7T--8",
  authDomain: "tuttogame-bd66c.firebaseapp.com",
  projectId: "tuttogame-bd66c",
  storageBucket: "tuttogame-bd66c.appspot.com",
  messagingSenderId: "981005736068",
  appId: "1:981005736068:web:9cd5bf6b4c3d8b98068ad1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;