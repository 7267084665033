import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import BuyNowModal from './components/BuyNowModal';
import GameNightSignupModal from './components/GameNightSignupModal';

// Manually set the number of images in each folder
const TOTAL_QUESTIONS = 14;
const TOTAL_ANSWERS = 18;

const reviews = [
  { text: '"I played the game its awesome and so relatable especially Tesla stocks and carbone spicy rigatoni 😂"' },
  { text: '"The ultimate vibe maker for dates and parties. 10/10 would embarrass myself again!"' },
  { text: '"My friends and I cant stop playing. So many unforgettable moments!"' },
];

function App() {
  const [showCards, setShowCards] = useState(false);
  const [questionCard, setQuestionCard] = useState(1);
  const [answerCards, setAnswerCards] = useState([1, 2, 3, 4]);
  const [eventImages, setEventImages] = useState([]);
  const [currentReview, setCurrentReview] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGameNightModalOpen, setIsGameNightModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Effect to handle /join route
  useEffect(() => {
    if (location.pathname === '/join') {
      setIsGameNightModalOpen(true);
    }
  }, [location.pathname]);

  const handleBuyNowClick = () => {
    setIsModalOpen(true);
  };

  const handleModalBuyNow = () => {
    const stripeLink = location.pathname === '/game' 
      ? 'https://buy.stripe.com/28ocN1aAo3Mz32U3cg'
      : 'https://buy.stripe.com/cN26oDgYMbf17jaeUZ';
    
    window.location.href = stripeLink;
  };

  useEffect(() => {
    const cardTimer = setTimeout(() => setShowCards(true), 500);

    // Load event images
    const eventImagesContext = require.context('../public/Events', false, /\.(png|jpe?g|svg)$/);
    const images = eventImagesContext.keys().map(eventImagesContext);
    setEventImages(images);

    const reviewInterval = setInterval(() => {
      setCurrentReview(prev => {
        const nextReview = (prev + 1) % reviews.length;
        return nextReview;
      });
    }, 6000); // Increased from 3000 to 6000 ms (6 seconds per review)

    return () => {
      clearTimeout(cardTimer);
      clearInterval(reviewInterval);
    };
  }, []);

  useEffect(() => {
    const changeInterval = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        replaceCard('question');
        answerCards.forEach((_, index) => replaceCard('answer', index));
        setIsChanging(false);
      }, 1000); // Duration of the change animation
    }, 3000);

    return () => clearInterval(changeInterval);
  }, [answerCards]);

  const replaceCard = (type, index) => {
    if (type === 'question') {
      setQuestionCard(prevCard => (prevCard % TOTAL_QUESTIONS) + 1);
    } else {
      setAnswerCards(prevCards => {
        const newCards = [...prevCards];
        newCards[index] = (newCards[index] % TOTAL_ANSWERS) + 1;
        return newCards;
      });
    }
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    // Optional: Toggle body scroll
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.menu-icon') && !event.target.closest('.menu-dropdown')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const HomeContent = () => (
    <div className="App">
      <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
        <div className="middle-line"></div>
      </div>
      <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}>
        <div className="menu-items">
          <button 
            className="menu-item" 
            onClick={() => {
              handleBuyNowClick();
              setIsMenuOpen(false);
            }}
          >
            buy tuttO
          </button>
          <a 
            href="https://www.instagram.com/playtutto" 
            className="menu-item" 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={() => setIsMenuOpen(false)}
          >
            connect
          </a>
          <button 
            className="menu-item"
            onClick={() => {
              setIsGameNightModalOpen(true);
              setIsMenuOpen(false);
            }}
          >
            join invite list
          </button>
          <div style={{
            fontSize: "24px",
            marginTop: "5px",
            marginBottom: "5px",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
          }}>
            we host flirty game nights
          </div>
          <div style={{
            fontSize: "24px",
            lineHeight: "1.4",
            maxWidth: "800px",
            padding: "0 20px",
            textAlign: "center",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
            marginTop: "10px"
          }}>
            Turn any gathering into an unforgettable night of laughter, flirting, and connection. Perfect for date nights, parties, or anywhere the mood strikes!
          </div>
        </div>
      </div>
      <div className="game-title">
        <img 
          src="/logoneww.png" 
          alt="play tuttO - the flirting game" 
          className="logo-image"
        />
      </div>
      <div className="game-area">
        <div 
          className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
          onClick={() => replaceCard('question')}
        >
          <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
        </div>
        <div className="answer-cards">
          {answerCards.map((card, index) => (
            <div
              key={index}
              className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
              style={{ animationDelay: `${index * 0.1}s` }}
              onClick={() => replaceCard('answer', index)}
            >
              {index === 3 ? (
                <div className="turn-on-card">
                  <img src="/turnon.jpg" alt="Turn On Card" />
                  <div className="turn-on-text">Write what turns you on</div>
                </div>
              ) : (
                <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
              )}
            </div>
          ))}
        </div>
      </div>
      <h2 style={{
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
        fontSize: "32px",
        lineHeight: "1.3",
        fontWeight: "400",
        maxWidth: "800px",
        margin: "0 auto 20px",
        padding: "0 20px",
        textAlign: "center",
        marginTop: isDesktop ? "40px" : "-80px"
      }}>
        <strong>tuttO</strong> is the spicy new party game where laughter leads to love... & unforgettable fun :)
      </h2>
      <div className="buttons-container" style={{ marginBottom: '30px', marginTop: '0' }}>
        <button 
          className="buy-now-button" 
          onClick={handleBuyNowClick}
          style={{
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
            background: "#FFF5EA !important",
            backgroundColor: "#FFF5EA !important",
            color: "#000000 !important",
            border: "1px solid #000000",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000000",
            marginBottom: "5px"
          }}
        >
          <span className="button-text" style={{ color: "#000000 !important", fontWeight: "normal" }}>get tuttO!</span>
        </button>
        <div style={{
          fontSize: "24px",
          marginTop: "5px",
          marginBottom: "5px",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
        }}>
          we host flirty game nights
        </div>
        <button 
          className="game-night-button"
          onClick={() => setIsGameNightModalOpen(true)}
          style={{
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
          }}
        >
          join invite list
        </button>
        <div style={{
          fontSize: "24px",
          lineHeight: "1.4",
          maxWidth: "800px",
          margin: "20px auto",
          padding: "0 20px",
          textAlign: "center",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
        }}>
          Turn any gathering into an unforgettable night of laughter, flirting, and connection. Perfect for date nights, parties, or anywhere the mood strikes!
        </div>
      </div>
      <div className="laurel-container">
        <div 
          className="laurel left" 
          style={{ 
            backgroundImage: 'url("/left.png")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <div className="review-container">
          <p className="review-text">{reviews[currentReview].text}</p>
        </div>
        <div 
          className="laurel right"
          style={{ 
            backgroundImage: 'url("/right.png")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
      </div>

      <div 
        className="product-image" 
        onClick={handleBuyNowClick}
        style={{
          paddingTop: '30px',
          transform: 'scale(0.9)'
        }}
      >
        <img src="/box2.png" alt="Tutto Game Box" />
      </div>

      <div className="game-explanation" style={{ marginTop: "20px" }}>
        <h2 className="game-rules">
          <span className="game-title-text">the way to play</span> 
          <ul>
            <li>pick a <span className="game-title-text">Q</span> Card from a deck of cheeky &  <br /> deep prompts</li>
            <li>craft your <span className="game-title-text">turnOn</span>~  write your wittiest, most seductive, or laugh-out-loud response</li>
            <li>feeling shy? use the <span className="game-title-text">flirtbot</span>  for guided flirtation</li>
            <li> <span className="game-title-text">happy ending</span>~ the player with the most captivating, swoon-worthy, or sidesplitting flirts scores</li>
          </ul>
        </h2>
      </div>

      <div className="brands-section">
        <p className="gathering-text">
          We partner with exceptional brands ~  spice up your next event with tuttO
        </p>
        <div className="brands-slider">
          <div className="brands-container">
            <img src="/brands/logo1.png" alt="Brand 1" className="brand-logo" />
            <img src="/brands/logo2.png" alt="Brand 2" className="brand-logo" />
            <img src="/brands/logo3.png" alt="Brand 3" className="brand-logo" />
            <img src="/brands/logo4.png" alt="Brand 4" className="brand-logo" />
            <img src="/brands/logo5.png" alt="Brand 5" className="brand-logo" />
          </div>
        </div>
      </div>

      <div style={{ 
        width: '100%', 
        overflow: 'hidden',
        paddingTop: '30px'
      }}>
        <div className="event-gallery">
          {eventImages.map((image, index) => (
            <img 
              key={`event-${index}`}
              src={image}
              alt={`Event ${index + 1}`}
              className="event-image"
              loading="lazy"
            />
          ))}
        </div>
      </div>

      <div style={{
        fontSize: "24px",
        fontWeight: "bold",
        margin: "20px 0",
        textAlign: "center",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
      }}>
        what turns you On?
      </div>
   <div style={{
        fontSize: "24px",
        fontWeight: "bold",
        margin: "20px 0",
        textAlign: "center",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
      }}>
tuttO é possibile      </div>
      <div className="buttons-container">
        <button 
          className="buy-now-button" 
          onClick={handleBuyNowClick}
          style={{
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
            background: "#FFF5EA !important",
            backgroundColor: "#FFF5EA !important",
            color: "#000000 !important",
            border: "1px solid #000000",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000000"
          }}
        >
          <span className="button-text" style={{ color: "#000000 !important", fontWeight: "normal" }}>get tuttO</span>
        </button>
        <button 
          className="game-night-button"
          onClick={() => setIsGameNightModalOpen(true)}
        >
          join event list
        </button>
      </div>

      <div className="social-links">
        <a 
          href="https://www.instagram.com/playtutto" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="instagram-link"
          style={{
            fontSize: "32px",
            color: "#000000",
            marginTop: "15px",
            display: "inline-block"
          }}
        >
          <i className="fab fa-instagram"></i>
        </a>
      </div>

      <p className="copyright">© tutto llc 2025</p>
    </div>
  );

  return (
    <>
      <Routes>
        <Route path="/game" element={(
          <div className="App">
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
              <div className="middle-line"></div>
            </div>
            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}>
              <div className="menu-items">
                <button 
                  className="menu-item" 
                  onClick={() => {
                    handleBuyNowClick();
                    setIsMenuOpen(false);
                  }}
                >
                  Buy Now
                </button>
                <a 
                  href="https://www.instagram.com/playtutto" 
                  className="menu-item" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Instagram
                </a>
                <button 
                  className="menu-item"
                  onClick={() => {
                    setIsGameNightModalOpen(true);
                    setIsMenuOpen(false);
                  }}
                >
                  Join Game Night Event Invite List
                </button>
              </div>
            </div>
            <div className="game-title">
              <img 
                src="/logoneww.png" 
                alt="play tuttO - the flirting game" 
                className="logo-image"
              />
            </div>
            <div className="game-area">
              <div 
                className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                onClick={() => replaceCard('question')}
              >
                <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
              </div>
              <div className="answer-cards">
                {answerCards.map((card, index) => (
                  <div
                    key={index}
                    className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                    style={{ animationDelay: `${index * 0.1}s` }}
                    onClick={() => replaceCard('answer', index)}
                  >
                    {index === 3 ? (
                      <div className="turn-on-card">
                        <img src="/turnon.jpg" alt="Turn On Card" />
                        <div className="turn-on-text">Write what turns you on</div>
                      </div>
                    ) : (
                      <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <h2>
              <span className="game-title-text">tuttO</span> is the spicy new party game where <br /> laughter leads to love...and unforgettable fun;) 
            </h2>
            <div className="buttons-container" style={{ marginBottom: '30px' }}>
              <button 
                className="buy-now-button" 
                onClick={handleBuyNowClick}
                style={{
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
                  background: "#FFF5EA !important",
                  backgroundColor: "#FFF5EA !important",
                  color: "#000000 !important",
                  border: "1px solid #000000",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#000000"
                }}
              >
                <span className="button-text" style={{ color: "#000000 !important", fontWeight: "normal" }}>buy tuttO</span>
              </button>
              <button 
                className="game-night-button"
                onClick={() => setIsGameNightModalOpen(true)}
              >
                Join tuttO Event Invite List
              </button>
            </div>
            <div className="game-explanation">
              <h2 className="game-rules">
                The way to play:
                <ul>
                  <li>Pick a Q Card: Dive into cheeky, provocative prompts.</li>
                  <li>Craft Your Turn-On: Write your wittiest, most seductive response.</li>
                  <li>Flirtbot to the Rescue: Feeling shy? Get a nudge with clever suggestions.</li>
                  <li>Win Your Happy Ending: The most captivating answer wins the round!</li>
                </ul>
              </h2>
              <p>Tutto isn’t just a game—it’s a moment. Unleash your charm, wit, and inner flirt in a battle for ultimate connection.</p>
            </div>
            
            <div className="laurel-container">
              <div 
                className="laurel left" 
                style={{ 
                  backgroundImage: 'url("/left.png")',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
              <div className="review-container">
                <p className="review-text">{reviews[currentReview].text}</p>
              </div>
              <div 
                className="laurel right"
                style={{ 
                  backgroundImage: 'url("/right.png")',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
            </div>

            <div 
              className="product-image" 
              onClick={handleBuyNowClick}
            >
              <img src="/box.png" alt="Tutto a Game Box" />
            </div>

            <div className="game-explanation" style={{ marginTop: "20px" }}>
              <h2 className="game-rules">
                <span className="game-title-text">the way to play</span> 
                <ul>
                  <li>pick a <span className="game-title-text">Q</span> Card from a deck of cheeky &  <br /> deep prompts</li>
                  <li>craft your <span className="game-title-text">turnOn</span>~  write your wittiest, most seductive, or laugh-out-loud response</li>
                  <li>feeling shy? use the <span className="game-title-text">flirtbot</span>  for guided flirtation</li>
                  <li> <span className="game-title-text">happy ending</span>~ the player with the most captivating, swoon-worthy, or sidesplitting flirts scores</li>
                </ul>
              </h2>
            </div>

            <div className="brands-section">
              <p className="gathering-text">
                We partner with exceptional brands ~  spice up your next event with tuttO
              </p>
              <div className="brands-slider">
                <div className="brands-container">
                  <img src="/brands/logo1.png" alt="Brand 1" className="brand-logo" />
                  <img src="/brands/logo2.png" alt="Brand 2" className="brand-logo" />
                  <img src="/brands/logo3.png" alt="Brand 3" className="brand-logo" />
                  <img src="/brands/logo4.png" alt="Brand 4" className="brand-logo" />
                  <img src="/brands/logo5.png" alt="Brand 5" className="brand-logo" />
                </div>
              </div>
            </div>

            <div style={{ width: '100%', overflow: 'hidden' }}>
              <div className="event-gallery">
                {eventImages.map((image, index) => (
                  <img 
                    key={`event-${index}`}
                    src={image}
                    alt={`Event ${index + 1}`}
                    className="event-image"
                    loading="lazy"
                  />
                ))}
              </div>
            </div>

            <div style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "20px 0",
              textAlign: "center",
              fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
            }}>
              what turns you on?
            </div>

            <div className="buttons-container">
              <button 
                className="buy-now-button" 
                onClick={handleBuyNowClick}
                style={{
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
                  background: "#FFF5EA !important",
                  backgroundColor: "#FFF5EA !important",
                  color: "#000000 !important",
                  border: "1px solid #000000",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#000000"
                }}
              >
                <span className="button-text" style={{ color: "#000000 !important", fontWeight: "normal" }}>buy tuttO</span>
              </button>
              <button 
                className="game-night-button"
                onClick={() => setIsGameNightModalOpen(true)}
              >
                join tuttO event list
              </button>
            </div>

            <div className="social-links">
              <a 
                href="https://www.instagram.com/playtutto" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="instagram-link"
                style={{
                  fontSize: "32px",
                  color: "#000000",
                  marginTop: "15px",
                  display: "inline-block"
                }}
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>

            <p className="copyright">© tutto llc 2024</p>

          
          </div>
        )} />
        <Route path="/join" element={<HomeContent />} />
        <Route path="/" element={<HomeContent />} />
      </Routes>
      
      <BuyNowModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onBuyNow={handleModalBuyNow}
      />

      <GameNightSignupModal
        isOpen={isGameNightModalOpen}
        onClose={() => {
          setIsGameNightModalOpen(false);
          if (location.pathname === '/join') {
            window.history.pushState({}, '', '/');
          }
        }}
      />
    </>
  );
}

export default App;