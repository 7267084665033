import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import app from '../firebase';
import './GameNightSignupModal.css';

function GameNightSignupModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    instagram: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const db = getFirestore(app);
      await addDoc(collection(db, 'gameNightSignups'), {
        ...formData,
        timestamp: new Date(),
      });

      setSubmitStatus('success');
      setTimeout(() => {
        window.open('https://www.instagram.com/playtutto/', '_blank');
        onClose();
        setFormData({ name: '', email: '', phone: '', instagram: '' });
        setSubmitStatus(null);
      }, 2000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
    setIsSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <div className="game-night-modal-overlay">
      <div className="game-night-modal">
        <button className="close-button" onClick={onClose}>&times;</button>
        <img 
          src="/image.png"
          alt="tutto Game Night Preview" 
          className="preview-image"
        />
        <p>Be the first to know about upcoming tutto game nights!</p>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="instagram"
              value={formData.instagram}
              onChange={handleChange}
              placeholder="Instagram Handle"
            />
          </div>
          <button 
            type="submit" 
            className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Joining...' : 'Join List'}
          </button>
          
          {submitStatus === 'success' && (
            <div className="status-message success">
              Successfully joined! Redirecting you to follow tutto on Instagram...
            </div>
          )}
          {submitStatus === 'error' && (
            <div className="status-message error">
              Oops! Something went wrong. Please try again.
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default GameNightSignupModal; 